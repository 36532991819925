@import '../styles/colors';
@import '../styles/variables';

@mixin outline-button-color($color: $accent1, $textColor: $color, $primaryAlt: white, $progressColor: lighten($color, 25%)) {
  border-color: $color;
  color: $textColor;

  &:focus, &:hover, &:visited {
    color: $textColor;
  }

  [class^="icon-"]:before {
    color: $textColor;
  }
  
  &.primary {
    background-color: $color;
    color: $primaryAlt;

    [class^="icon-"]:before {
      color: $primaryAlt;
    }
  }

  .lc-outline-button-progress {
    background-color: lighten($color, 25%);
  }
}

@mixin outline-button($padding: $marginMd) {
  height: 28px;
  border: solid 2px;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0 $padding;
  border-radius: 16px;
  vertical-align: middle;
  font-size: 11px;
  line-height: 1;
  text-transform: uppercase;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  background-color: transparent;
  box-sizing: border-box;

  &:focus, &:hover, &:visited {
    outline-style: none;
    text-decoration: none;
  }

  &.xl {
    font-size: 14px;
    height: 48px;
    border-radius: 25px;
    padding: 0 $padding * 2;
  }

  &.lg {
    font-size: 14px;
    height: 36px;
    border-radius: 25px;
    padding: 0 $padding;
  }

  &.sm {
    height: 24px;
    padding: 0 $padding;
    font-size: 10px;
  }

  &.xs {
    height: 20px;
    padding: 0 $padding*0.5;
    font-size: 10px;
  }

  .lc-outline-button-progress-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
    border-radius: 16px;

    .lc-outline-button-progress {
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }

  @include outline-button-color();

  &.red {
    @include outline-button-color($accent8);
  }

  &.blue {
    @include outline-button-color($accent5);
  }

  &.light-blue {
    @include outline-button-color($accent6);
  }

  &.lighter-blue {
    @include outline-button-color($accent2, $primaryAlt: $accent6);
  }

  &.white {
    @include outline-button-color(white, $primaryAlt: $accent1, $progressColor: $accent6);
  }

  &.grey {
    @include outline-button-color($color: $base5, $textColor: $base3);
  }

  &.light-grey {
    @include outline-button-color($color: $base7, $textColor: $base5, $primaryAlt: $accent1);
  }

  &.disabled {
    background-color: transparent;
    border-color: $base6;
    color: $base6;
    
    span:before {
      color: $base6 !important;
    }
    cursor: not-allowed;
  }

  // Icon specific tweaks

  .icon-upload {
    font-size: 17px;
  }
  .icon-checked {
    font-size: 15px;
  }
  .icon-edit-video {
    font-size: 18px;
    &:before {

      margin-top: 2px;
    }
  }

  .lc-Outline-button-icon {
    margin-right: $marginSm*0.5;

    img {
      margin-top: 2px;
      margin-bottom: 2px;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .lc-Outline-button-label {
    font-weight: 600;
    z-index: 1;
  }

  .lc-outline-button-badge {
    position: absolute;
    top: -$marginSm;
    right: -$marginSm;
    min-width: $marginSm;
    line-height: 1.5em;

    border-radius: $marginSm;
    background-color: $accent8;
    color: white;
    padding: 0 5px;
    font-size: 10px;
  }
}

.lc-Outline-button {
  @include outline-button();
}
