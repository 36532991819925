@import "../../../styles/colors";
@import "../../../styles/variables";

.lc-record-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;


  .lc-start-recording,
  .lc-stop-recording {
    min-width: 32px;
    max-width: 32px;
    padding: $marginXs $marginSm;
    font-size: 24px;
    cursor: pointer;
  }

  .lc-start-recording {
    color: $accent8;

    &.Mui-disabled {
      color: $base5;
      cursor: not-allowed;
    }
  }

  .lc-stop-recording {
    color: $base1;
  }

  .lc-record-timer {
    font-size: 14px;
    font-weight: 500;
  }

  .lc-record-start-recording {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }
  }
}