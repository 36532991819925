@import "../../../styles/variables";

.lc-recordings-dialog {
  .lc-recordings-dialog-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $marginSm $marginMd;

    .lc-recordings-dialog-title {
      font-size: 18px;
      font-weight: 500;
    }

    .lc-recordings-dialog-close {
      margin-left: auto;
    }
  }
}