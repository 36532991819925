@import "../../styles/colors";
@import "../../styles/variables";

.lc-meeting-room-header {
  height: $roomHeaderHeight;
  padding: $marginSm;
  background-color: $base8;
  position: sticky;
  top: 0;
  z-index: 10;

  .lc-meeting-room-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: $roomHeaderHeight - 2*$marginSm;
  }

  .lc-meeting-room-logo {
    max-width: $roomHeaderHeight - $marginMd;
    max-height: $roomHeaderHeight - $marginMd;
    margin-right: $marginMd;
    border-radius: $marginMd*0.5;
    box-shadow: 2px 2px 4px 1px rgba(0,0,0,0.25);
  }

  .lc-meeting-room-title {
    font-size: 24px;
    font-weight: 600;
    color: $base1;
    margin-bottom: $marginXs;
  }

  .lc-recording-list {
    flex-direction: row;

    .lc-record-button {
      margin-right: $marginSm;
    }

    @media (max-width: $maxMobileWidth) {
      .lc-recording-line {
        .lc-recording {
          .lc-audio-player {
            min-width: 150px;
            max-width: 150px;
          }
        }
      }
    }
  }

  .lc-meeting-room-sync-btn {
    margin-left: auto;
  }

  @media (max-width: $maxMobileWidth) {
    .lc-meeting-room-header-container {
      padding: 0;
    }

    .lc-meeting-room-logo {
      max-width: $roomHeaderHeight * 0.5;
      max-height: $roomHeaderHeight * 0.5;
    }

    .lc-meeting-room-header-main {
      max-width: calc(100% - #{$roomHeaderHeight*0.5 + $marginMd + 50px});

      .lc-meeting-room-title {
        font-size: 20px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .lc-meeting-room-sync-btn {
      width: 50px;

      .lc-Outline-button-icon {
        font-size: 16px;
        margin-right: 0;
      }
      .lc-Outline-button-label {
        display: none;
      }
    }
  }
}