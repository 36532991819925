@import '../../styles/colors';
@import '../../styles/variables';

.lc-app-container {
  .lc-content-container {
    height: calc(100vh - #{$headerHeight + $roomHeaderHeight});

    .lc-muted-warning {
      border: solid 1px $accent8;
      border-radius: $marginSm;
      background-color: $accent4;
      margin: $marginMd $marginMd 0;
      padding: $marginSm;
      color: $accent8;
      display: flex;
      flex-direction: row;
      align-items: center;

      .lc-muted-warning-icon {
        font-size: 28px;
        margin-right: $marginMd;
      }
    }
  
    .lc-meeting-participants-container {
      padding-top: $marginMd;
      
      @media (min-width: $minDesktopWidth) {
        padding-left: $marginSm;
        padding-right: $marginSm;
        max-height: 100%;
        overflow-y: auto;
      }
    }
  }

  .lc-alert {
    font-weight: 600;
    font-size: 16px;

    &.lc-alert-success {
      color: $base9;
      background-color: $accent9;
      
      .MuiAlert-icon {
        color: $base9;
      }
    }

    &.lc-alert-error {
      color: $base9;
      background-color: $accent8;

      .MuiAlert-icon {
        color: $base9;
      }
    }

  }
}