@import "../../styles/colors";
@import "../../styles/variables";

.lc-header {
  height:$headerHeight;
  background-color: white;

  padding: $marginSm;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .lc-header-left,
  .lc-header-right {
    display: flex;
    flex-direction: row;
    flex-basis: 50%;
    flex-grow: 1;
    align-items: center;
  }

  .lc-header-right {
    justify-content: flex-end;
    color: $base2;
  }
}
