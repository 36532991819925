$base0: #1F2324;
$base1: #333637;
$base2: #354F55;
$base3: #6E7D82;
$base4: #939393;
$base5: #C4C4C4;
$base6: #E3E3E3;
$base7: #EEEEEE;
$base8: #F6F6F6;
$base9: #FCFCFC;

$accent1: #4A3DE4;
$accent2: #E7FBFC;
$accent3: #FFE583;
$accent4: #FFDDF4;
$accent5: #0745C9;
$accent6: #0FAEF2;
$accent7: #172325;
$accent8: #FF1E3D;
$accent9: #78D081;
