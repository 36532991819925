@import "../../../styles/colors";
@import "../../../styles/variables";

.lc-recording-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: $marginXs;

  $btnWidth: 32px;

  .lc-recording-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: $marginXs 0;

    @media (max-width: $maxMobileWidth) {
      flex-direction: column;
    }

    .lc-recording-more-link {
      font-size: 12px;
      font-weight: 500;
      color: $accent1;
      cursor: pointer;
      margin-left: $marginXs;

      &:hover {
        font-weight: 600;
      }
    }
  }

  .lc-recording {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // margin-left: $btnWidth;

    .lc-recording-status {
      padding-left: $marginLg;
      font-size: 14px;
      font-weight: 500;
    }

    .lc-recording-delete-btn {
      min-width: $btnWidth;
      max-width: $btnWidth;
      color: $base1;
      font-size: 20px;

      &.disabled {
        color: $base6;
        cursor: not-allowed;
      }
    }
  }

  .lc-script-original-recording {
    padding: $marginXs 0;

    &.has-recordings {
      margin-right: $btnWidth;
    }

    .lc-audio-player {
      background-color: $accent2;
    }
  }

  .lc-audio-player {
    height: 30px;

    @media only screen and (max-width: 480px) {
      max-width: 150px;
    }
  }
}