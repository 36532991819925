@import "../../../styles/colors";
@import "../../../styles/variables";

.lc-episode-script {
    padding: 15px;

    .lc-script-section {
        white-space: break-spaces;
        background-color: $base8;
        padding: 10px 15px;
        margin-top: 15px;
        border-radius: 5px;

        .lc-script-section-title {
            color: $base1;
            font-weight: 500;
            font-size: 18px;
            margin-top: 5px;
            margin-bottom: 20px;
        }
    }
}
