$marginMd: 15px;
$marginSm: $marginMd*0.5;
$marginXs: $marginSm*0.5;
$marginLg: $marginMd*2;
$marginXl: $marginLg*2;

$headerHeight: 50px;
$roomHeaderHeight: 100px;

$maxMobileWidth: 479px;
$minDesktopWidth: $maxMobileWidth + 1px;
