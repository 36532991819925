@import "../../../styles/colors";
@import "../../../styles/variables";

.lc-script-card {
  font-size: 14;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;

  $btnWidth: 32px;
  
  .lc-script-card-header {
    min-height: 40px;
    padding: 0 10px;
    border-bottom: solid 1px $base7;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;

    .lc-script-card-icon {
      color: $accent1;
    }

    .lc-script-card-collapse-btn {
      cursor: pointer;
      min-width: $btnWidth;
      max-width: $btnWidth;
      padding: 3px 5px;
    }
  }
  
  .lc-script-card-content {
    padding: 0 10px;
    $line-height: 22px;
    $underline-offset: 3px;

    line-height: $line-height;
    margin-top: $line-height + $underline-offset;
    min-height: 3*$line-height;
    padding-bottom: $line-height - $underline-offset;
    background-image: repeating-linear-gradient(white 0px, white ($line-height - $underline-offset - 1), $base7 ($line-height - $underline-offset), white ($line-height - $underline-offset + 1), white $line-height);
  }

  &.collapsed {

    &:after {
      height: 25px;
      font-size: 18px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      content: '...';
    }

    .lc-script-card-content {
      max-height: 50px;
      overflow-y: hidden;
    }
  }

}