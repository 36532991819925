@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i');

html {
  height: 100%;
  position: relative;

  body {
    font-family: 'Poppins', sans-serif;

    #root {
      height: 100vh;
      position: relative;
      overflow-x: hidden;
    }
  }
}