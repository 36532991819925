@import "../../styles/colors";
@import "../../styles/variables";

@mixin lc-audio-player($height: 30px) {
  $height: 30px;
  padding: 5px 0;

  height: $height;
  background-color: $base7;
  border-radius: $height*0.5;
  width: auto;
  min-width: 200px;
  max-width: 400px;

  position: relative;

  &.NotStarted {
    width: 100px;
  }

  &.Planned {
    .icon-schedule {
      margin-right: $marginSm;

      &:before {
        color: $base1;
      }
    }
  }

  &.is-over {
    background-color: $accent2;
  }

  &.is-recording,
  &.has-audio {
    width: auto;
    min-width: 230px;

    .lc-episode-recording-controls {
      margin-left: $marginXs;
      width: 20px;
      cursor: pointer;
    }
  }

  .lc-episode-recording-progress-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: $height*0.5;
    overflow: hidden;
    cursor: pointer;

    .lc-episode-recording-progress {
      height: 100%;
      background-color: $accent3;
    }
    
    &.lc-recording-upload-progress {
      .lc-episode-recording-progress {
        background-color: $accent2;
      }
    }
  }

  .lc-episode-recording-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin: 0 $marginXs;

    .lc-episode-recording-controls,
    .lc-episode-recording-main,
    .lc-icon-menu-button {
      z-index: 2;
    }
  }

  .lc-episode-recording-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  .lc-episode-recording-main {
    font-size: 12px;
    pointer-events: none;
    line-height: 20px;
  }
}

.lc-audio-player {
  @include lc-audio-player();
}